<template>
  <main class="space-y-12 pb-36 pt-12">
    <div class="space-y-4">
      <AppPanel
        v-if="$store.state.error"
        variant="error"
      >
        <BzlTitlePair centered>
          <template #title>
            <span class="text-white">
              {{ errorMessage }}
            </span>
          </template>
        </BzlTitlePair>
        <div class="flex flex-row justify-center gap-4">
          <p v-html="$store.state.error.subtitle"></p>
        </div>
      </AppPanel>

      <BzlTitlePair
        v-if="$store.state.internet.services"
        centered
      >
        <template #title>Review Available Speeds</template>
      </BzlTitlePair>
      <AppPanel>
        <BzlTitlePair centered>
          <template #title>To change your plan</template>
          <template #subtitle>
            Call customer service at
            <a
              :href="`tel:${$store.state.customerServicePhoneNumber}`"
              class="font-bold underline"
              v-text="$store.state.formattedCustomerServicePhoneNumber"
            ></a>
            or <span class="font-bold">chat</span>
          </template>
        </BzlTitlePair>
        <div class="flex flex-row justify-center gap-4">
          <BzlButton
            size="large"
            variant="primary"
            :href="`tel:${$store.state.customerServicePhoneNumber}`"
          >
            Call us
          </BzlButton>
          <BzlButton
            id="chat"
            size="large"
            variant="default"
          >
            Chat with us
          </BzlButton>
        </div>
      </AppPanel>
    </div>

    <div class="space-y-4">
      <BzlTitlePair
        v-if="$store.state.internet.services"
        centered
      >
        <template #title>Available Plans</template>
      </BzlTitlePair>
      <AppPanel
        v-for="service in $store.state.internet.availableServices"
        :key="service.id"
      >
        <AppInternetServiceItem
          :service="service"
          :is-selectable="false"
          v-on="$listeners"
        ></AppInternetServiceItem>
      </AppPanel>
    </div>

    <AppWizardNav
      color="dark"
      inner-class="justify-center"
    >
      <BzlButton
        variant="default"
        size="large"
        :href="$store.getters['myAccountUrl']"
        target="_self"
      >
        <BzlIcon name="house-door-fill"></BzlIcon>
        Back to My Account
      </BzlButton>
    </AppWizardNav>
  </main>
</template>

<script>
  import { addClickToSupport } from '../ccai/ccai'
  export default {
    name: 'CallToUpgradePage',
    data() {
      return {
        errorMessage: '',
      }
    },
    mounted() {
      this.trackEvent('OS_REVIEW_AVAILABLE_SPEEDS_ERROR')
      this.fetchError()
      addClickToSupport()
    },
    methods: {
      fetchError() {
        this.errorMessage = this.$store.state.error?.title
      },
    },
  }
</script>
