<template>
  <div class="AppPanel">
    <sl-details
      v-if="collapsible"
      v-bind="{ ...$props, ...$attrs }"
      :summary="label"
      class="collapsible"
      v-on="$listeners"
    >
      <template v-if="$slots.warning">
        <div class="bg-salmon p-2 text-center text-sm text-white">
          <slot name="warning"></slot>
        </div>
      </template>
      <div class="space-y-4 p-4 sm:p-6">
        <slot></slot>
      </div>
      <template v-if="$slots.footnote">
        <div class="bg-gray-light p-2 text-center text-sm">
          <slot name="footnote"></slot>
        </div>
      </template>
    </sl-details>
    <div
      v-else
      class="overflow-hidden rounded-lg lg:h-full"
      v-bind="{ ...$props, ...$attrs }"
      :class="classes"
    >
      <template v-if="label">
        <div class="label">
          {{ label }}
        </div>
      </template>
      <template v-if="$slots.warning">
        <div class="bg-salmon p-2 text-center text-sm font-bold text-navy-dark">
          <slot name="warning"></slot>
        </div>
      </template>
      <div class="space-y-4 p-6 lg:space-y-6">
        <slot></slot>
      </div>
      <template v-if="$slots.footnote">
        <div class="bg-gray-light p-2 text-center text-sm">
          <slot name="footnote"></slot>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  // https://shoelace.style/components/details
  import '@shoelace-style/shoelace/dist/components/details/details.js'

  export default {
    name: 'AppPanel',
    inheritAttrs: false,
    props: {
      /* Native props */
      open: {
        type: Boolean,
        required: false,
        default: false,
      },
      /* Custom props */
      label: {
        type: String,
        required: false,
        default: null,
      },
      variant: {
        type: String,
        required: false,
        default: 'primary',
        validator: (val) =>
          [
            'primary', // white
            'alternate', // light gray
            'blue-da-ba-dee', // blue!
            'error', // salmon
            'transparent',
          ].includes(val),
      },
      collapsible: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    computed: {
      classes() {
        const classes = []

        if (!this.collapsible) {
          classes.push(
            {
              primary: `text-navy-dark bg-white shadow`,
              alternate: `text-navy-dark bg-gray-light shadow`,
              'blue-da-ba-dee': `text-white bg-blue shadow`,
              error: `text-white bg-error shadow`,
              transparent: `text-navy-dark bg-transparent shadow-none p-0`,
            }[this.variant]
          )
        }

        return classes.join(' ')
      },
    },
  }
</script>

<style>
  .AppPanel .collapsible::part(base) {
    @apply overflow-hidden rounded-lg border-none shadow;
  }

  .AppPanel .label,
  .AppPanel .collapsible::part(header) {
    @apply rounded-none bg-navy-dark p-4 text-center font-bold text-white;
  }

  .AppPanel .collapsible::part(content) {
    @apply bg-white p-0;
  }

  .AppPanel .collapsible::part(summary) {
  }
  .AppPanel .collapsible::part(summary-icon) {
  }

  .AppPanel hr {
    @apply border-gray;
  }
</style>
