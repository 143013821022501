<template>
  <div>
    <div v-if="labelsAreloading">
      <Skeleton></Skeleton>
    </div>

    <div
      v-if="showBroadbandLabels && !labelsAreloading && !this.$store.state.BroadbandLabels.labelsError"
      class="w-full text-navy-dark"
    >
      <div class="productLabel border-navy-dark px-4 pt-2 text-left">
        <h2 class="showBroadbandLabels text-2lg border-b-2 border-navy-dark">Broadband Facts</h2>

        <div v-html="getLabelHtml"></div>
      </div>
    </div>
    <div class="labels-error-div" v-else>
      We are experiencing technical difficulties. Please visit your Myaccount online portal later to view a label describing key features of your Breezeline's service option.
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions, mapState } from 'vuex'
  import Skeleton from './Skeleton.vue'

  export default {
    name: 'AppCart',
    components: {
      Skeleton,
    },
    props: {
      showBroadbandLabels: {
        type: Boolean,
        default: true,
      },
      name: {
        type: String,
        default: null,
      },
      download: {
        type: Number,
        default: 0,
      },
      upload: {
        type: Number,
        default: 0,
      },
      fullPrice: {
        type: Number,
        default: 0,
      },
    },
    computed: {
      ...mapGetters({
        internet: 'Buyflow/internet/selectedService',
      }),
      ...mapState('BroadbandLabels', ['labelData', 'labelsAreloading']),
      ...mapState({
        offerId: (state) => state.meta.offerId,
        marketType: (state) => state.meta.marketType,
        stateOrProvince: (state) => state.account.address.state,
      }),
      plan() {
        return this.getProductLabelData(this.name)
      },
      getLabelHtml() {
        return this.plan?.labels?.[0]?.labelHtml
      },
    },
    mounted() {
      this.fetchBroadbandLabel({
        planName: this.name, // product name
        tier: this.download, // download speed
        downloadSpeed: this.download * 1000, // this.speed.download, // Download Speed
        uploadSpeed: this.getUploadSpeed() * 1000, // Upload Speed
        promotionalPriceDuration: 0, // Promo Months
        price: this.toCent(this.fullPrice), // EDP
        promotionalPrice: this.toCent(this.fullPrice), // Promo Price
        offerId: '0', // Offer ID from CSG
        marketCode: 'none', // Market Type
        hasPromotionalPrice: false, // True if promo price is different from EDP,
        additionalCharges: {
          providerMonthlyFees: {
            paperStatementFee: {
              title: 'Paper Statement Fee',
              value: this.toCent(this.paperStatementFee()),
            },
          },
        },
      })
    },
    methods: {
      ...mapActions('BroadbandLabels', ['fetchBroadbandLabel']),
      toCent(amount) {
        if (typeof amount === 'string') {
          amount = parseFloat(amount)
        }

        // Check if amount is now a valid number
        if (typeof amount !== 'number' || isNaN(amount)) {
          console.error('Invalid input to toCent function:', amount)
          return 0
        }

        // Multiply by 100 and round to nearest integer
        return Math.round(amount * 100)
      },
      setFrn() {
        this.frnNumber = this.getProductLabelData(this.name)?.labels?.[0]?.labelFrnIdentifier ?? ''
        this.$store.commit('BroadbandLabels/SET_PLAN_ID', this.frnNumber)
      },
      getProductLabelData(offerName) {
        const matchedData = this.labelData
          .flat()
          .find((data) => data?.labels?.some((label) => label.name === offerName))
        return matchedData || false
      },
      getUploadSpeed() {
        switch (this.name) {
          case 'Base Internet':
            return 10
          case 'Unlimited Fast':
            return 20
          case 'Unlimited UltraFast':
            return 50
          case 'Unlimited GigaFast':
            return 50
          case 'Unlimited SuperFast':
            return 20
          case 'Unlimited Fiber Fast':
            return 200
          case 'Unlimited Fiber UltraFast':
            return 500
          case 'Unlimited Fiber GigaFast':
            return 600
          default:
            return this.upload
        }
      },
      paperStatementFee() {
        const states = [
          { state: 'CT', price: '0.00' },
          { state: 'DE', price: '3.00' },
          { state: 'FL', price: '3.00' },
          { state: 'MA', price: '0.00' },
          { state: 'MD', price: '3.00' },
          { state: 'ME', price: '0.00' },
          { state: 'NH', price: '3.00' },
          { state: 'NY', price: '0.00' },
          { state: 'OH', price: '3.00' },
          { state: 'PA', price: '3.00' },
          { state: 'SC', price: '3.00' },
          { state: 'VA', price: '3.00' },
          { state: 'WV', price: '0.00' },
        ]
        const result = states.find((item) => item.state === this.stateOrProvince)
        return result ? result.price : '0.00'
      },
    },
  }
</script>
<style scoped>
.labels-error-div{
  background-color: #FFF5F4;
  border: 1px solid lightcoral;
  font-weight: 700;
  color: red;
  padding: 20px;
  margin-top: 20px;
}
</style>
