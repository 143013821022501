// Google Tag Manager
const gtm = document.createElement('script')
gtm.src = `https://www.googletagmanager.com/gtag/js?id=${import.meta.env.VITE_GTM_TRACKING_ID}`
document.body.appendChild(gtm)

gtm.onload = () => {
  window.gtag('js', new Date())
  window.gtag('config', import.meta.env.VITE_GTM_TRACKING_ID, {
    app_name: import.meta.env.VITE_APP_NAME,
    send_page_view: false,
    user_id: window?.marketingId ?? null,
  })

  /* eslint-disable */
  // prettier-ignore
  window.gtag.events = {
    trigger_mode_eligible_learn_more_CTA: [
      'mobile',
      'trigger_mode_eligible_learn_more_CTA',
      'eligible user clicks learn more',
    ],
    trigger_mode_ineligible_earn_more_CTA: [
      'mobile',
      'trigger_mode_ineligible_earn_more_CTA',
      'ineligible user clicks learn more',
    ],
    trigger_manage_mobile_CTA: [
      'mobile',
      'trigger_manage_mobile_CTA',
      'user clicks manage accout button',
    ],
    trigger_loyalty_dashboard_banner_dismissed: [
      'account',
      'trigger_loyalty_dashboard_banner_dismissed',
      'user dismisses the banner',
    ],
    trigger_loyalty_dashboard_banner_clicked: [
      'account',
      'trigger_loyalty_dashboard_banner_clicked',
      'user dismisses the banner',
    ],
    trigger_enroll_breezeline_loyalty: [
      'account',
      'trigger_enroll_breezeline_loyalty',
      'user dismisses the banner',
    ],
    trigger_unenroll_breezeline_loyalty: [
      'account',
      'trigger_unenroll_breezeline_loyalty',
      'user dismisses the banner',
    ],
    trigger_referral_code_dashboard_banner_dismissed: [
      'account',
      'trigger_referral_code_dashboard_banner_dismissed',
      'user dismisses the banner',
    ],
    trigger_referral_code_dashboard_banner_clicked: [
      'account',
      'trigger_referral_code_dashboard_banner_clicked',
      'user clicks here in the referrall code banner',
    ],
    trigger_referral_code_learn_more_clicked: [
      'account',
      'trigger_referral_code_learn_more_clicked',
      'user clicks learn more in account tab',
    ],
    trigger_referral_code_copied: [
      'account',
      'trigger_referral_code_copied',
      'user clciks tab to copy',
    ],
    trigger_privacy_policy_clicked: [
      'account',
      'trigger_privacy_policy_clicked',
      'user clicks privacy policy',
    ],
    trigger_pay_my_bill_click: [
      'payment',
      'trigger_pay_my_bill_click',
      'user clicks pay my bill',
    ],
    trigger_chat_with_us_clicked: [
      'account',
      'trigger_chat_with_us_clicked',
      'user clicks chat with us',
    ],
    trigger_error_making_a_promise_to_pay: [
      'payment',
      'trigger_error_making_a_promise_to_pay',
      'error recieved from promise to pay submit',
    ],
    CONFIRM_CANCEL_OF_PROMISE_TO_PAY: [
      'payment',
      'trigger_confirm_cancel_of_promise_to_pay',
      'promoise to pay is successfully cancelled',
    ],
    trigger_terms_and_conditions_clicked: [
      'account',
      'trigger_terms_and_conditions_clicked',
      'user clicks terms and conditions',
    ],
    trigger_call_us_clicked: [
      'account',
      'trigger_call_us_clicked',
      'user clicks the call number',
    ],
    trigger_need_help_click: [
      'account',
      'trigger_need_help_click',
      'need help clicked',
    ],
    trigger_view_scheduled_payments_clicked: [
      'payment',
      'trigger_view_scheduled_payments_clicked',
      'user clicks on scheduled payment',
    ],
    trigger_check_my_email_click: [
      'account',
      'trigger_check_my_email_click',
      'user clicks check my email',
    ],
    trigger_search_button_click: [
      '',
      'trigger_search_button_click',
      'search form is submitted',
    ],
    trigger_statement_history_clicked: [
      'account',
      'trigger_statement_history_clicked',
      'user clicks statement',
    ],
    trigger_payment_history_clicked: [
      'payment',
      'trigger_payment_history_clicked',
      'user clicks payment history',
    ],
    trigger_login_screen_outages_clicked: [
      'outages',
      'trigger_login_screen_outages_clicked',
      'user clicks outages on dashboard',
    ],
    trigger_promise_to_pay_clicked: [
      'payment',
      'trigger_promise_to_pay_clicked',
      'user clicks schedule promise to pay',
    ],
    trigger_seasonal_plans_link_clicked: [
      'account',
      'trigger_seasonal_plans_link_clicked',
      'user clicks seasonal plans',
    ],
    trigger_reset_password_clicked: [
      '',
      'trigger_reset_password_clicked',
      'user clicks reset password',
    ],
    trigger_visit_support_center_clicked: [
      'account',
      'trigger_visit_support_center_clicked',
      'user clicks find answers online',
    ],
    trigger_support_center_clicked: [
      'account',
      'trigger_support_center_clicked',
      'user clicks support center',
    ],
    trigger_promise_to_pay_submit: [
      'payment',
      'trigger_promise_to_pay_submit',
      'user submits a successfull promise to pay',
    ],
    trigger_make_a_payment_clicked: [
      'payment',
      'trigger_make_a_payment_clicked',
      'user clicks pay now button',
    ],
    P_PAY_NOW_CLOSED: [
      'payment',
      'trigger - pay now closed',
      'user closes pay now window',
    ],
    trigger_pay_full_amount_clicked: [
      'payment',
      'trigger_pay_full_amount_clicked',
      'user selects to pay the full balance',
    ],
    trigger_pay_partial_amount_clicked: [
      'payment',
      'trigger_pay_partial_amount_clicked',
      'user selects to pay part of the balance',
    ],
    P_DATE_SELECTED_TODAY: [
      'payment',
      'trigger - date selected today',
      'user selects today to schedule a payment',
    ],
    P_DATE_SELECTED_DUE_DATE: [
      'payment',
      'trigger - date selected due date',
      'user selects the due date to schedule a payment',
    ],
    trigger_date_selected: [
      'payment',
      'trigger_date_selected',
      'user selects a date from the calendar to schedule a payment',
    ],
    trigger_payment_method_option_clicked: [
      'payment',
      'trigger_payment_method_option_clicked',
      'user selects a payment method',
    ],
    trigger_review_or_modify_payment_clicked: [
      'payment',
      'trigger_review_or_modify_payment_clicked',
      'user selects continue to get to payment confirmation',
    ],
    trigger_re_enter_payment_info: [
      'payment',
      'trigger_re_enter_payment_info',
      'user goes back to enter different payment information',
    ],
    trigger_submit_payment_clicked: [
      'account',
      'trigger_submit_payment_clicked',
      'user clicks confirm to make payment',
    ],
    A_PAYMENT_METHOD_CLICKED: [
      'autopay',
      'trigger - payment method clicked',
      'user selects autopay payment method',
    ],
    trigger_enable_autopay_clicked: [
      'autopay',
      'trigger_enable_autopay_clicked',
      'user clicks enable autopay button',
    ],
    A_CHANGE_AUTOPAY_CLICKED: [
      'autopay',
      'trigger - change autopay clicked',
      'user clicks changes autopay button',
    ],
    trigger_unenrolled_autopay: [
      'account',
      'trigger_unenrolled_autopay',
      'user clicks confirm to disable autopay',
    ],
    A_CONFIRM_CHANGE_AUTOPAY_CLICKED: [
      'autopay',
      'trigger - confirm changed autopay clicked',
      'user confirms changed autopay payment method',
    ],
    A_REJECT_CHANGE_AUTOPAY_CLICKED: [
      'autopay',
      'trigger - reject changed autopay clicked',
      'user rejects changed autopay payment method',
    ],
    ES_ENABLE_ECOSAVE_CLICKED: [
      'account',
      'trigger - ecosave on',
      'user toggles ecosave on',
    ],
    ES_DISABLE_ECOSAVE_CLICKED: [
      'account',
      'trigger - ecosave off',
      'user toggles ecosave off',
    ],
    trigger_add_a_payment_method_clicked: [
      'stored payment methods',
      'trigger_add_a_payment_method_clicked',
      'user adds a new payment method to stored payment methods',
    ],
    trigger_delete_payment_method_clicked: [
      'stored payment methods',
      'trigger_delete_payment_method_clicked',
      'user clicks delete payment method button',
    ],
    SPM_CONFIRM_DELETE_PAYMENT_METHOD_CLICKED: [
      'stored payment methods',
      'trigger - confirm delete payment method clicked',
      'user confirms deletion of payment method',
    ],
    SPM_ADD_PAYMENT_METHOD_SUCCESS: [
      'account',
      'trigger - save payment method',
      'user clicks save after entering new payment method',
    ],
    SPM_ADD_PAYMENT_METHOD_WITH_PAYMENT_SUCCESS: [
      'stored payment methods',
      'trigger - add a payment method with payment amount success',
      'user successfully added a new payment method and made a one time payment',
    ],
    trigger_user_logged_out: [
      'exit',
      'trigger_user_logged_out',
      'user session ended',
    ],
    trigger_reset_modem_clicked: [
      'equipment',
      'trigger_reset_modem_clicked',
      'user clicks reset modem on a modem item',
    ],
    EQ_START_SPEED_TEST: [
      'equipment',
      'trigger - start speed test clicked',
      'user clicks start speed test link',
    ],
    trigger_order_remote_clicked: [
      'equipment',
      'trigger_order_remote_clicked',
      'user clicks order new remote link',
    ],
    trigger_equipment_return_clicked: [
      'equipment',
      'trigger_equipment_return_clicked',
      'user clicks equipment return',
    ],
    trigger_general_support_link_clicked: [
      'account',
      'trigger_general_support_link_clicked',
      'user clicks general support link',
    ],
    trigger_unenrolled_paperless_billing: [
      'account',
      'trigger_unenrolled_paperless_billing',
      'user clicks confirm to opt out of paperless billing',
    ],
    AC_SERVICE_CENTER: [
      'account',
      'trigger - service center clicked',
      'user clicks service center link',
    ],
    trigger_statement_details: [
      'account',
      'trigger_statement_details',
      'DYNAMIC DATE?',
    ],
    trigger_check_for_outages_clicked: [
      'account',
      'trigger_check_for_outages_clicked',
      'user clicks check for outages',
    ],
    D_VIEW_BILL: [
      'account',
      'trigger - view bill clicked',
      'user clicks view bill link',
    ],
    trigger_bill_explainer_clicked: [
      'billing',
      'trigger_bill_explainer_clicked',
      'user clicks bill explainer link',
    ],
    trigger_billing_clicked: [
      'account',
      'trigger_billing_clicked',
      'user clicks billing center link',
    ],
    trigger_manage_payment_methods_clicked: [
      'billing',
      'trigger_manage_payment_methods_clicked',
      'user clicks manage payment methods link',
    ],
    B_SCHEDULED_PAYMENTS: [
      'billing',
      'trigger - scheduled payments clicked',
      'user clicks scheduled payments link',
    ],
    trigger_enrolled_paperless_billing: [
      'billing',
      'trigger_enrolled_paperless_billing',
      'user clicks paperless billing link',
    ],
    trigger_view_statements_clicked: [
      'billing',
      'trigger_view_statements_clicked',
      'user clicks statements and payments link',
    ],
    A_SAVE_PAYMENT: [
      'account',
      'trigger - save payment method',
      'user clicks save after entering new payment method',
    ],
    trigger_download_breezeline_stream_tv: [
      'app downloads',
      'trigger_download_breezeline_stream_tv',
      'user clicks link to download stream tv app',
    ],
    trigger_download_wifi_your_way: [
      'app downloads',
      'trigger_download_wifi_your_way',
      'user clicks link to download wifi your way app',
    ],
    trigger_download_breezeline_tv: [
      'app downloads',
      'trigger_download_breezeline_tv',
      'user clicks link to download breezeline tv app',
    ],
    AD_MY_BREEZELINE: [
      'app downloads',
      'trigger - download my breezeline mobile app',
      'user clicks link to download my breezeline mobile app',
    ],
    trigger_start_chat: [
      'chat',
      'trigger_start_chat',
      'user clicks start chat after entering personal info',
    ],
    PT_SUBMIT_AUTOPAY: [
      'account',
      'trigger - confirm autopay setup',
      'user clicks confirm to enable autopay',
    ],

    // Upgrade aka Order Services (OS_) events. comments above events refer to
    // description on trigger given in the document provided.

    // Review available speeds - where it shows speed
    trigger_change_your_speed_clicked: [
      'order services',
      'trigger_change_your_speed_clicked',
      'able to upgrade online'
    ],
    // Review available speeds - speed up(going to prompt them to call in )
    OS_REVIEW_AVAILABLE_SPEEDS_ERROR: [
      'order services',
      'review available speeds',
      'prompt to call in'
    ],
    // Tooltip for modem upgrade (if modem upgrade is applicable)
    OS_TOOLTIP_CLICK: [
      'order services',
      'tooltip click',
      'modem upgrade tooltip',
    ],
    // what current speed
    OS_CURRENT_SPEED: [
      'order services',
      'current speed',
      '*custom event to let us know what their current speed is in mbps*', // this should be modified when tracking it
    ],
    // slow down click
    OS_SLOW_DOWN_CLICK: [
      'order services',
      'slow down click',
      'review downgrade options',
    ],
    // Take speed quiz
    OS_TAKE_SPEED_QUIZ: [
      'order services',
      'take speed quiz',
      'speed quiz',
    ],
    // continue
    OS_CONTINUE_NEW_SPEED: [
      'order services',
      'continue - new speed',
      '*custom event to let us know what speed they are changing to in mbps*', // this should be modified when tracking it
    ],
    // Checkout
    OS_CONTINUE_HARDWARE_SELECTIONS: [
      'order services',
      'continue - hardware selections',
      '*custom to let us know what hardware was selected* The 3 options would be "gateway" or "gateway and wyw" or "own modem"' // this should be modified when tracking it
    ],
    // Finalize page
    OS_CHECKOUT_FINALIZE_PAGE: [
      'order services',
      'finalize page',
      'confirm checkout',
    ],
    // Signup for autopay now
    OS_AUTOPAY_SIGNUP: [
      'order services',
      'post checkout',
      'sign up for autopay now',
    ],

    // Easy Pay events
    EP_CONTINUE_PAYMENT_METHOD: [
      'easy pay',
      'continue to payment method',
      'continue'
    ],
    EP_VERIFY: [
      'easy pay',
      'verify payment method',
      'continue to confirmation'
    ],
    EP_SUBMIT: [
      'easy pay',
      'submit one time payment',
      'submitted'
    ]
  }
  /* eslint-enable */
}
