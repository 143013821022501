<template>
  <a
    v-if="$attrs.href"
    v-bind="{ ...$props, ...$attrs }"
    :class="computedClasses"
    class="BzlInlineLink"
    style="appearance: none"
    v-on="$listeners"
  >
    <slot></slot>
  </a>
  <button
    v-else
    v-bind="{ ...$props, ...$attrs }"
    class="BzlInlineLink"
    :class="computedClasses"
    style="appearance: none"
    v-on="$listeners"
  >
    <slot></slot>
  </button>
</template>

<script>
  import '@shoelace-style/shoelace/dist/components/button/button.js'

  export default {
    name: 'BzlInlineLink',
  }
</script>

<style>
  .BzlInlineLink {
    @apply
      text-navy-dark
      cursor-pointer
      relative
      max-w-max
      transition-all duration-[350]
      hover:text-blue
      p-0;
  }
  .BzlInlineLink::before,
  .BzlInlineLink::after {
    @apply
      content-['']
      absolute
      w-full
      border-b-[1px]
      border-navy-dark
      bottom-[-3px]
      left-0
      opacity-100
      transform
      scale-x-100
      origin-right;
  }

  .BzlInlineLink::after {
    @apply
      opacity-0
      scale-x-0
      origin-left;
  }

  .BzlInlineLink:hover::before {
    @apply
      opacity-0
      border-blue
      scale-x-0;
    transition: opacity 0.2s ease-in-out 0.2s, transform 0.2s ease-in-out 0s;
  }

  .BzlInlineLink:hover::after {
    @apply
      opacity-100
      border-blue
      scale-x-100;
    transition: transform 0.2s ease-in-out 0.25s;
  }
</style>
