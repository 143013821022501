<template>
  <div>
    <div v-if="variant === 'mini'">
      <span class="text-base font-bold text-navy-dark">${{ dollar }}.{{ cents }}</span>
      <span class="text-sm text-navy">{{ miniSubtext }}</span>
      <BzlIconButton
        v-if="showDisclaimer"
        class="text-sm"
        name="info-circle-fill"
        label="More Information"
        @click="(e) => $emit('on-disclaimer-click', e)"
      ></BzlIconButton>
    </div>

    <div v-else>
      <div class="flex flex-col items-center gap-2">
        <slot name="eyebrow"></slot>
        <div class="relative flex flex-row items-stretch justify-center gap-1">
          <div
            class="relative flex flex-row items-start gap-0.5 tracking-tighter text-navy-dark"
            :class="crossedOutClasses"
          >
            <span
              class="font-bold"
              :style="{ fontSize: '2rem', lineHeight: 1 }"
            >
              $
            </span>
            <span
              class="font-heavy"
              :style="{ fontSize: '4rem', lineHeight: 0.8 }"
            >
              {{ dollar }}
            </span>
          </div>

          <div
            class="flex flex-col leading-none"
            :class="recurring && unit ? 'justify-center' : 'justify-start'"
          >
            <div
              class="font-bold text-navy-dark"
              :class="`${crossedOutClasses} ${unit ? 'text-lg' : 'text-xl'}`"
            >
              {{ cents }}<template v-if="showAsterisk">*</template>
              <BzlIconButton
                v-if="showDisclaimer"
                class="text-lg"
                name="info-circle-fill"
                label="More Information"
                @click="(e) => $emit('on-disclaimer-click', e)"
              ></BzlIconButton>
            </div>
            <div
              class="text-sm text-navy"
              :class="crossedOutClasses"
            >
              {{ recurring }}
            </div>
            <div
              class="text-sm text-navy"
              :class="crossedOutClasses"
            >
              {{ unit }}
            </div>
            <span
              v-if="isCrossedOut"
              class="absolute left-0 right-0 top-1/2 h-2 origin-center border-salmon bg-salmon"
              :style="{ width: '110%', transform: 'rotate(-18deg) translateX(-2.5%)' }"
            ></span>
          </div>
        </div>
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'BzlPriceDisplay',
    props: {
      price: {
        type: Number,
        required: true,
      },
      recurring: {
        type: String,
        required: false,
        default: '/mo.',
      },
      unit: {
        type: String,
        required: false,
        default: null,
      },
      showDisclaimer: {
        // todo: merge with showAsterisk
        type: Boolean,
        required: false,
        default: false,
      },
      showAsterisk: {
        type: Boolean,
        required: false,
        default: false,
      },
      variant: {
        type: String,
        required: false,
        default: 'default',
        validator: (val) => ['default', 'mini'].includes(val),
      },
      isCrossedOut: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    computed: {
      normalizedPrice() {
        return this.price.toFixed(2).split('.')
      },
      dollar() {
        return this.normalizedPrice[0]
      },
      cents() {
        return this.normalizedPrice[1]
      },
      crossedOutClasses() {
        return this.isCrossedOut ? `filter grayscale opacity-75` : null
      },
      miniSubtext() {
        let subtext = ''

        if (this.recurring.length) {
          subtext += `${this.recurring}`
        }

        if (this.unit?.length) {
          subtext += ` ${this.unit}`
        }

        if (this.showAsterisk) {
          subtext += '*'
        }

        return subtext
      },
    },
  }
</script>
