import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

import account from './account'
import internet from './internet'
import tv from './tv'
import voice from './voice'
import cart from './cart'
import equipment from './equipment'
import products from './products'
import order from './order'
import LabelStore from './labelsStore'

import router from '../router'

import { Alert } from '$/Alert.js'

axios.defaults.baseURL = import.meta.env.VITE_API_URL
axios.defaults.headers.common['X-Set-CSG-Environment'] = 'QAHA'

if (import.meta.env.VITE_API_CSG_ENV) {
  axios.defaults.headers.common['X-Set-CSG-Environment'] = import.meta.env.VITE_API_CSG_ENV
}

Vue.use(Vuex)

export default new Vuex.Store({
  namespaced: true,

  modules: {
    account,
    internet,
    tv,
    voice,
    cart,
    equipment,
    products,
    order,
    BroadbandLabels: LabelStore,
  },

  state: {
    envMode: import.meta.env.MODE,
    appIsReady: false,
    appIsProcessing: false,
    alert: null,
    error: null,
    customerServicePhoneNumber: '18445058406',
    formattedCustomerServicePhoneNumber: '1 (844) 505-8406',
  },

  getters: {
    onDev() {
      return import.meta.env.DEV
    },
    onProd() {
      return import.meta.env.PROD
    },
    myAccountUrl() {
      return import.meta.env.VITE_MYACCOUNT_URL || 'https://myaccount.breezeline.com'
    },
  },

  mutations: {
    SHOW_APP(state) {
      state.appIsReady = true
    },
    LOCK_APP(state) {
      state.appIsProcessing = true
    },
    UNLOCK_APP(state) {
      state.appIsProcessing = false
    },
    PUSH_ERROR(state, payload) {
      console.log('PUSH_ERROR mutation called')
      state.error = new Alert(payload)
    },
    PUSH_ALERT(state, payload) {
      state.alert = new Alert(payload)
    },
  },

  actions: {
    async initializeAll({ dispatch, commit, rootState }) {
      // gets user account data from graphql
      await dispatch('account/getAccountData')

      await dispatch('account/initialize')

      await dispatch('account/getAddress')

      // initialize the change order with csg
      await dispatch('order/initialize')

      if (!rootState.products.options) {
        await dispatch('products/getProducts')
      }

      if (!rootState.order.order.internet) {
        await dispatch('order/addInitialOffers')
        await dispatch('order/updateState')
      }
    },

    lockApp({ commit }) {
      commit('LOCK_APP')
    },

    unlockApp({ commit }) {
      commit('UNLOCK_APP')
    },
    showApp({ commit }) {
      commit('SHOW_APP')
    },
    showError({ dispatch, state }, error) {
      // console.log('error:', error.message);
      let errorMessage = 'Sorry, there was a problem processing your request.'

      if (error.message.includes('An open order exists for this location')) {
        errorMessage = `There was a problem processing your request due to an existing order in progress.`
      }

      const alert = {
        variant: 'danger',
        title: errorMessage,
        subtitle:
          `To continue making changes to your account, our Customer Care ` +
          `team would be happy to assist you at ` +
          `<a href="tel:${state.customerServicePhoneNumber}">` +
          `${state.formattedCustomerServicePhoneNumber}</a>`,
        closable: false,
        duration: 1000000000,
      }

      dispatch('pushError', alert)
    },
    handleError({ dispatch }, error) {
      if (import.meta.env.MODE === 'development') {
        console.error(error)
      }

      if (import.meta.env.MODE !== 'development' && window.Sentry) {
        window.Sentry.captureException(error)
      }

      if (
        error?.response?.data?.message === 'Invalid conversationId' ||
        error.cause === 'conversationId'
      ) {
        dispatch('restartUpgrade')
        return
      }

      if (router.currentRoute.name !== 'manual-upgrade') {
        console.log('Redirecting to upgrade page')
        router.push('manual-upgrade')
      }

      dispatch('showError', error)
    },
    showAlert({ dispatch, state }, alert) {
      // saving this space in case we need to modify the alert
      dispatch('pushAlert', alert)
    },
    async restartUpgrade({ dispatch }) {
      dispatch('lockApp')
      dispatch('account/clearLocalStorage')

      await dispatch('initializeAll')

      router.push({ name: 'change-your-speed' })

      dispatch('unlockApp')
    },
    pushError({ commit }, payload) {
      commit('PUSH_ERROR', payload)
    },
    pushAlert({ commit }, payload) {
      commit('PUSH_ALERT', payload)
    },
  },
})
