import Vue from 'vue'
import { DateTime } from 'luxon'

export function trackEvent(name, optionalDate) {
  const thisEvent = window.gtag?.events[name]

  if (!import.meta.env.PROD) {
    console.log('Firing event %s', name)
  }

  if (import.meta.env.PROD && thisEvent) {
    // Brenden only wants the event name. He said we dont need the category, action and label.
    window.gtag('event', thisEvent[1], {
      user_id: window.marketingId ?? null,
    })
  }
}

export function splitPrice(price) {
  return price.toFixed(2).split('.')
}

export function toFriendlyDate(date = null, syntax = 'full') {
  const ordinalSuffix = (day) => {
    if (day >= 11 && day <= 13) {
      return 'th'
    }
    const lastDigit = day % 10
    switch (lastDigit) {
      case 1:
        return 'st'
      case 2:
        return 'nd'
      case 3:
        return 'rd'
      default:
        return 'th'
    }
  }

  let pattern

  switch (syntax) {
    case 'super-short-numeric-slash':
      pattern = 'M/d'
      break
    case 'short-numeric-slash':
      pattern = 'M/d/yy'
      break
    case 'short-numeric-dash':
      pattern = 'M-d-yy'
      break
    case 'credit-card-expiry-short':
      pattern = 'MM/yy'
      break
    case 'credit-card-expiry-full':
      pattern = 'MM/yyyy'
      break
    case 'full-truncated':
      pattern = 'LLL d, yyyy'
      break
    case 'full-truncated-no-year':
      pattern = 'LLL d'
      break
    case 'ISO8601':
      pattern = 'yyyy-MM-dd'
      break
    case 'localized-date-with-abbreviated-month':
      pattern = 'DD'
      break
    case 'full':
    default:
      pattern = 'LLLL do, yyyy'
      break
  }

  if (date !== 'AUTO PAY' && date !== 'ON RECPT') {
    const jsDate = new Date(date)
    const dayOfMonth = jsDate.getDate()

    const luxonDate = DateTime.fromJSDate(jsDate, { zone: 'UTC' })

    // split the pattern into parts
    const patternParts = pattern.split('do')

    // use Luxon for formatting non-'do' parts
    let formattedDate = ''
    patternParts.forEach((part, index) => {
      formattedDate += luxonDate.toFormat(part)
      // add custom logic for ordinal numbers only for the 'do' part
      if (index < patternParts.length - 1) {
        formattedDate += `${dayOfMonth}${ordinalSuffix(dayOfMonth)}`
      }
    })
    return formattedDate
  } else {
    return ''
  }
}

export function monthsFromDate(num, date, pattern = 'yyyy-MM-dd') {
  if (!date) {
    return null // handle the case where date is undefined or null
  }

  return DateTime.fromFormat(date, pattern).plus({ months: num })
}

export function daysFromToday(num) {
  // answers: what is the date num days from today?
  return DateTime.local().plus({ days: num }).toJSDate()
}

export function howManyDaysFromToday(date) {
  // Answers: How many days from today is this date?

  if (!date) {
    return null // or handle the case where date is undefined or null
  }

  const today = DateTime.local().startOf('day')
  const dateWithoutTime = DateTime.fromJSDate(new Date(date)).startOf('day')

  const diffInDays = dateWithoutTime.diff(today, 'days').toObject().days
  return Math.floor(diffInDays)
}

export function getDiffInDays(date1, date2) {
  // No longer in use but keeping here in case we need it again
  // returns the difference (in days, as integer) between two dates
  return DateTime.fromJSDate(date1).diff(DateTime.fromJSDate(date2), 'days').days
}

export function isTodayOrBefore(date) {
  const today = DateTime.local()
  return DateTime.fromJSDate(date).startOf('day') <= today.startOf('day')
}

export function generateUUID() {
  /* eslint-disable */
  let d = new Date().getTime()
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (d + Math.random() * 16) % 16 | 0
    d = Math.floor(d / 16)
    return (c === 'x' ? r : (r & 0x7) | 0x8).toString(16)
  })
  /* eslint-enable */
}

export function isValidEmail(email) {
  let flag = false

  /* eslint-disable */
  if (email && email.length) {
    if (
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
        email.toLowerCase()
      )
    ) {
      flag = true
    }
  }
  /* eslint-enable */

  return flag
}

export function isAndroid() {
  return /Android/i.test(navigator.userAgent)
}

export function isIOS() {
  return /iPhone|iPad|iPod/i.test(navigator.userAgent)
}

// https://orangeable.com/javascript/slugify
export function slugify(str) {
  return str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '')
}

export function toCurrency(val) {
  return `$${(val / 100).toFixed(2)}`
}

/*
  This opens the base64 PDF in a new tab, as an actual PDF.
  Tested to work on desktop (Chrome) and mobile (Safari) so far.

  Source: https://www.ottorinobruni.com/how-to-solve-safari-pdf-iphone-ipad-load-first-page-problem/
*/
export function openPdfInNewTab(data) {
  let byteCharacters = atob(encodeURI(data).replace(/\s/g, ''))
  let byteNumbers = new Array(byteCharacters.length)
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }

  let byteArray = new Uint8Array(byteNumbers)
  let file = new Blob([byteArray], { type: 'application/pdf;base64' })
  let fileURL = URL.createObjectURL(file)
  return window.open(fileURL)
}

// https: //stackoverflow.com/a/72659289/2162750
export function isCCExpired(str) {
  const [mm, yy] = str.split('/')
  const dateObj = new Date(+yy, mm - 1, 15) // months are 0 based and don't take the 1st due to timezones
  return dateObj.getTime() < new Date().getTime()
}

export function getFeatureFlagWithOverrides(flagName, overrideAccounts) {
  if(overrideAccounts === this.$store.state.login.email) {
    return true;
  }
  return (flagName.toLowerCase() === 'true');
}

Vue.mixin({
  methods: {
    trackEvent,
    splitPrice,
    toFriendlyDate,
    monthsFromDate,
    daysFromToday,
    howManyDaysFromToday,
    getDiffInDays,
    isTodayOrBefore,
    generateUUID,
    isValidEmail,
    isAndroid,
    isIOS,
    slugify,
    openPdfInNewTab,
    isCCExpired,
    toCurrency,
    getFeatureFlagWithOverrides,
  },
})
