<template>
  <div>
    <div class="cursor-pointer space-y-2">
      <div class="relative">
        <img
          :src="thumbnailSrc"
          class="rounded-md"
        />
        <div
          class="absolute inset-0 z-50 flex flex-row items-center justify-center"
          @click="toggle"
        >
          <BzlIcon
            name="play-circle"
            class="text-4xl text-white"
          ></BzlIcon>
        </div>
      </div>
      <slot
        name="label"
        :toggle="toggle"
      ></slot>
    </div>
    <sl-dialog
      :open="isVisible"
      :no-header="true"
      @sl-after-hide="isVisible = false"
    >
      <iframe
        v-if="isVisible"
        ref="video"
        :src="`https://www.youtube.com/embed/${videoId}?autoplay=1&controls=0`"
        class="h-64 w-full"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      <slot></slot>
    </sl-dialog>
  </div>
</template>

<script>
  export default {
    name: 'AppVideoModal',
    props: {
      videoId: {
        type: String,
        required: true,
      },
      thumbnailSrc: {
        type: String,
        required: true,
      },
    },
    data: () => ({
      isVisible: false,
    }),
    methods: {
      toggle() {
        this.isVisible = !this.isVisible
      },
    },
  }
</script>
