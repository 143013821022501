<template>
  <main class="space-y-4 pb-32 md:pb-36">
    <BzlTitlePair centered>
      <template #title>Your New Service</template>
    </BzlTitlePair>

    <div class="grid grid-cols-12">
      <AppPanel class="col-span-full lg:col-span-8 lg:col-start-3">
        <div class="lg:p-8">
          <AppCart
            title="Updated Service"
            :cart-data="$store.state.cart"
            :hardware-editable="requiresModemUpgrade"
          ></AppCart>
          <hr class="my-6 border-2 lg:my-8" />
          <div class="space-y-4 rounded-md py-6">
            <div class="text-lg font-bold">Confirm your order:</div>
            <p>
              By clicking “Complete My Order”, you (i) acknowledge that you have reviewed and
              understand the estimated charges and fees for your selected services, and (ii)
              acknowledge that you have read and agree to be bound by Breezeline’s Residential
              Subscriber Agreement, available
              <a
                href="https://www.breezeline.com/assets/documents/Residential-Subscriber-Agreement-Rev-6-14-2022.pdf"
                target="_blank"
                class="underline"
                >here</a
              >.
            </p>
            <p class="font-bold">
              Please be aware that if you change your service, you will lose your promotional
              discount.
            </p>
            <div>
              <label>
                <BzlCheckbox
                  ref="confirmButton"
                  name="iConfirm"
                  @sl-change="onConfirmCheck"
                >
                  I confirm
                </BzlCheckbox>
              </label>
            </div>
          </div>
        </div>
      </AppPanel>
    </div>

    <AppWizardNav color="dark">
      <a
        target="_self"
        class="flex items-center gap-2 px-4 text-white hover:underline"
        @click="$router.push(backLinkUrl)"
      >
        <BzlIcon name="arrow-left"></BzlIcon>
        <span class="hidden sm:inline">{{ backLinkText }}</span>
        <span class="sm:hidden">Back</span>
      </a>

      <span @click="onDisabled">
        <BzlButton
          variant="default"
          size="large"
          :disabled="!isConfirmed"
          class="completeOrder"
          @click="onNextPage"
        >
          Complete My Order
          <BzlIcon name="check-circle-fill"></BzlIcon>
        </BzlButton>
      </span>
    </AppWizardNav>
  </main>
</template>

<script>
  export default {
    name: 'CartPage',
    beforeRouteEnter(to, from, next) {
      next((vm) => {
        if (from.name === 'manual-upgrade') {
          vm.fromManualUpgrade = true
        } else {
          vm.fromManualUpgrade = false
        }
      })
    },
    data: () => ({
      isConfirmed: false,
      fromManualUpgrade: false,
    }),
    computed: {
      requiresModemUpgrade() {
        return !this.$store.state.cart.services.internet.modems.includes(
          this.$store.state.account.modem
        )
      },
      backLinkText() {
        if (this.requiresModemUpgrade) {
          return 'Hardware Requirement'
        }

        return 'Change your Speed'
      },
      backLinkUrl() {
        if (this.requiresModemUpgrade) {
          return {
            name: 'hardware-requirement',
          }
        }

        return {
          name: 'change-your-speed',
        }
      },
    },
    async mounted() {
      if (this.fromManualUpgrade) {
        return
      }

      this.$store.dispatch('lockApp')

      try {
        // need to determine the shipping
        await this.$store.dispatch('order/determineShipping')

        await this.$store.dispatch('order/submitOfferConfiguration')

        const order = await this.$store.dispatch('order/getAppointments')

        if (order.appointments.length) {
          const appointmentParams = {
            id: this.$store.state.order.order.appointments.id,
            date: this.$store.state.order.order.appointments.dates[0].date,
            timeSlot: this.$store.state.order.order.appointments.dates[0].timeSlots[0],
          }

          await this.$store.dispatch('order/submitAppointment', appointmentParams)
        }

        await this.$store.dispatch('order/getSummary')
      } catch (error) {
        if (error.cause === 'conversationId') {
          this.$store.dispatch('restartUpgrade')
          return
        }

        this.$store.dispatch('showError', error)
        this.$router.push({ name: 'manual-upgrade' })
      }

      this.$store.dispatch('unlockApp')
    },
    methods: {
      onConfirmCheck(e) {
        this.isConfirmed = e.target.checked
      },
      onDisabled(event) {
        if (event.target !== event.currentTarget) {
          return
        }

        if (!this.isConfirmed) {
          const alert = {
            variant: 'danger',
            title: 'Error',
            subtitle: `Please confirm`,
            closable: true,
            duration: 5000,
          }

          this.$store.dispatch('showAlert', alert)

          this.$refs.confirmButton.$el.classList.add('flash')

          window.setTimeout(() => {
            this.$refs.confirmButton.$el.classList.remove('flash')
          }, 2000)

          this.$refs.confirmButton.$el.scrollIntoView({ behavior: 'smooth' })
        }
      },
      async onNextPage() {
        this.$store.dispatch('lockApp')

        try {
          this.$store.dispatch('order/submitOrder')
          this.trackEvent('OS_CHECKOUT_FINALIZE_PAGE')
          this.$router.push({ name: 'confirmation' })
        } catch (error) {
          if (error.cause === 'conversationId') {
            this.$store.dispatch('restartUpgrade')
            return
          }

          this.$store.dispatch('showError', error)
          this.$router.push({ name: 'manual-upgrade' })
        }

        this.$store.dispatch('unlockApp')
      },
    },
  }
</script>

<style scoped>
  .completeOrder {
    display: inline-block;
  }

  .completeOrder[disabled] {
    position: relative;
    z-index: -1;
  }

  .flash {
    animation-name: flash;
    animation-duration: 1000ms;
    animation-iteration-count: 2;
    animation-timing-function: ease;
  }

  @keyframes flash {
    0% {
      @apply bg-blue-light;
      opacity: 1;
    }

    100% {
      background-color: inherit;
    }
  }
</style>
